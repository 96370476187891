//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'
import useLoginInformation from '~/composables/useLoginInformation'

export default defineComponent({
  props: {
    loggedOutContent: {
      type: Array,
      default: () => []
    },
    loggedInContent: {
      type: Array,
      default: () => []
    },
    sectionBackground: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { userIsLoggedIn } = useLoginInformation()

    const contentToDisplay = computed(() => {
      if (userIsLoggedIn.value) {
        return props.loggedInContent
      }
      return props.loggedOutContent
    })

    return {
      contentToDisplay,
      userIsLoggedIn
    }
  }
})
